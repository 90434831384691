*{
  font-family: "Jost", sans-serif;
  box-sizing: border-box;
}

html,body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
 